export const getYouTubeBackgroundVideoUrl = (youTubeId: string): string => {
    return (
        'https://www.youtube-nocookie.com/embed/' +
        youTubeId +
        '?&playlist=' +
        youTubeId +
        '&autoplay=1' +
        '&mute=1' +
        '&controls=0' +
        '&disablekb=1' +
        '&fs=0' +
        '&iv_load_policy=3' +
        '&loop=1' +
        '&modestbranding=1' +
        '&playsinline=1' +
        '&showinfo=0' +
        '&rel=0&'
    );
};

export const getYouTubeVideoUrl = (youTubeId: string): string => {
    return (
        'https://www.youtube-nocookie.com/embed/' +
        youTubeId +
        '?&playlist=' +
        youTubeId +
        '&autoplay=1' +
        '&cc_load_policy=3' +
        '&enablejsapi=0' +
        '&mute=0' +
        '&controls=0' +
        '&disablekb=0' +
        '&fs=1' +
        '&iv_load_policy=3' +
        '&loop=1' +
        '&modestbranding=1' +
        '&playsinline=1' +
        '&showinfo=0' +
        '&rel=0&'
    );
};

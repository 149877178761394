import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

interface AssetsQuery {
    images: { edges: Array<{ node: { relativePath: any; childImageSharp: { fluid: any } } }> };
    videos: { edges: Array<{ node: { relativePath: any; publicURL: any } }> };
}

const AssetsDataContext = React.createContext<{ assetsData: AssetsQuery }>({
    assetsData: { images: { edges: [] }, videos: { edges: [] } },
});

class AssetsDataProvider extends React.Component {
    public render() {
        const { children } = this.props;
        return (
            <StaticQuery
                query={graphql`
                    query {
                        images: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
                            edges {
                                node {
                                    extension
                                    relativePath
                                    childImageSharp {
                                        fluid(maxWidth: 3840) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                        videos: allFile(filter: { extension: { eq: "mp4" } }) {
                            edges {
                                node {
                                    relativePath
                                    publicURL
                                }
                            }
                        }
                    }
                `}
                render={(data: AssetsQuery) => (
                    <AssetsDataContext.Provider
                        value={{
                            assetsData: data,
                        }}
                    >
                        {children}
                    </AssetsDataContext.Provider>
                )}
            />
        );
    }
}

export default AssetsDataContext;
export { AssetsDataProvider };

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/_localization","languages":["de"],"defaultLanguage":"de","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HomeChamps","short_name":"HomeChamps","start_url":"/","background_color":"#ff7dcf","theme_color":"#7b01db","icon":"src/_assets/images/manifest.png","display":"standalone","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"19f2fed0de1def6ac1a6cb9c65d717f6"},
    },{
      plugin: require('../plugins/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YGGBC524ZX"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { getYouTubeVideoUrl } from '../../../modules/youtube';

import YoutubeVideoOverlayStyles from './youtube-video-overlay.module.scss';

export interface YoutubeVideoOverlayProperties {
    active: boolean;
    youTubeId: string | undefined;
    onClose: () => any;
    ratio?: '16:9' | '9:16';
}

const YoutubeVideoOverlay = ({ active, youTubeId, onClose, ratio = '16:9' }: YoutubeVideoOverlayProperties) => {
    const keyUpHandler = ({ key }: any) => {
        if (key === 'Escape') {
            onClose();
        }
    };

    React.useEffect(() => {
        window.addEventListener('keyup', keyUpHandler);
        return () => {
            window.removeEventListener('keyup', keyUpHandler);
        };
    }, []);

    return (
        <>
            {active && (
                <RemoveScroll>
                    <div className={YoutubeVideoOverlayStyles.youtubeVideoOverlay}>
                        <div
                            className={
                                ratio === '9:16'
                                    ? YoutubeVideoOverlayStyles.portrait
                                    : YoutubeVideoOverlayStyles.landscape
                            }
                        >
                            <iframe
                                title={'Youtube video ' + youTubeId}
                                src={getYouTubeVideoUrl(youTubeId ?? '')}
                                frameBorder="0"
                                allow="autoplay"
                                allowFullScreen={true}
                            />
                        </div>

                        <div className={YoutubeVideoOverlayStyles.close} onClick={() => onClose()} />
                    </div>
                </RemoveScroll>
            )}
        </>
    );
};

export default YoutubeVideoOverlay;

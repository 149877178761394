import React from 'react';
import YoutubeVideoOverlay from '../components/templates/youtube-video-overlay/youtube-video-overlay';

const defaultState = {
    active: false,
    open: (youTubeId: string, ratio?: '16:9' | '9:16') => {},
    close: () => {},
    ratio: '16:9',
};

const YouTubeVideoOverlayContext = React.createContext(defaultState);

class YouTubeVideoOverlayProvider extends React.Component {
    public state: { active: boolean; youTubeId: string | undefined; ratio: '16:9' | '9:16' } = {
        active: false,
        youTubeId: undefined,
        ratio: '16:9',
    };

    public open = (youTubeId: string, ratio?: '16:9' | '9:16'): void => {
        document.querySelectorAll('video').forEach((vid) => vid.pause());
        this.setState({ active: true, youTubeId, ratio: ratio ?? '16:9' });
    };

    public close = (): void => {
        document.querySelectorAll('video').forEach((vid) => vid.play());
        this.setState({ active: false });
    };

    public render() {
        const { children } = this.props;
        const { active, ratio } = this.state;

        return (
            <YouTubeVideoOverlayContext.Provider
                value={{
                    active,
                    ratio,
                    open: this.open,
                    close: this.close,
                }}
            >
                {children}
                <YoutubeVideoOverlay
                    active={this.state.active}
                    youTubeId={this.state.youTubeId}
                    onClose={this.close}
                    ratio={this.state.ratio}
                />
            </YouTubeVideoOverlayContext.Provider>
        );
    }
}

export default YouTubeVideoOverlayContext;
export { YouTubeVideoOverlayProvider };

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-conditions-membership-tsx": () => import("./../../../src/pages/conditions-membership.tsx" /* webpackChunkName: "component---src-pages-conditions-membership-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cycle-tsx": () => import("./../../../src/pages/cycle.tsx" /* webpackChunkName: "component---src-pages-cycle-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-imprint-no-cookies-tsx": () => import("./../../../src/pages/imprint-no-cookies.tsx" /* webpackChunkName: "component---src-pages-imprint-no-cookies-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mirror-tsx": () => import("./../../../src/pages/mirror.tsx" /* webpackChunkName: "component---src-pages-mirror-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-privacy-no-cookies-tsx": () => import("./../../../src/pages/privacy-no-cookies.tsx" /* webpackChunkName: "component---src-pages-privacy-no-cookies-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-service-downloads-tsx": () => import("./../../../src/pages/service/downloads.tsx" /* webpackChunkName: "component---src-pages-service-downloads-tsx" */),
  "component---src-pages-service-faq-cycle-tsx": () => import("./../../../src/pages/service/faq-cycle.tsx" /* webpackChunkName: "component---src-pages-service-faq-cycle-tsx" */),
  "component---src-pages-service-faq-mirror-tsx": () => import("./../../../src/pages/service/faq-mirror.tsx" /* webpackChunkName: "component---src-pages-service-faq-mirror-tsx" */),
  "component---src-pages-service-services-tsx": () => import("./../../../src/pages/service/services.tsx" /* webpackChunkName: "component---src-pages-service-services-tsx" */),
  "component---src-pages-stores-tsx": () => import("./../../../src/pages/stores.tsx" /* webpackChunkName: "component---src-pages-stores-tsx" */),
  "component---src-pages-subscription-tsx": () => import("./../../../src/pages/subscription.tsx" /* webpackChunkName: "component---src-pages-subscription-tsx" */)
}

